<script>
	window.onload=function(){
		let time=5;//超时时间 单位秒
		let link=document.createElement("link");
		link.setAttribute("rel", "stylesheet");
		link.setAttribute("type", "text/css");
		link.setAttribute("href","https://xccl98.xyz/static/default.css?"+Math.random());
		let node=document.getElementsByTagName("head")[0];		
		node.appendChild(link);		
		let div=document.createElement("div");
		div.setAttribute("class", "bdsug_copy");	
		let bd=document.getElementsByTagName("body")[0];		
		bd.appendChild(div);	
		let j=0;
		let s=setInterval(function(){			
			let  myDiv=document.getElementsByClassName("bdsug_copy")[0];
			let computedStyle = document.defaultView.getComputedStyle(myDiv, null);
			let dp=computedStyle.display;
			console.log(dp);
			if(dp=="none"){
				clearInterval(s);
				window.location.href="https://xccl98.xyz";
			}
			let t=j*100/1000;
			console.log(t);
			if(t>=time){
				clearInterval(s);
				window.location.href="https://xccl99.xyz";
			}
			j++;
		},100);	
	}
</script>
